// src/controllers/pin_menu_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'middletag', 'navtag', 'button' ]

  connect() {
    this.addObserver()
    if(localStorage.getItem('navShouldBeOpen') == true) {
      if ($('#aside')[0]) {
        $('#aside')[0].style['transition-duration'] = '0s'
        $('#aside')[0].style['-webkit-transition-duration'] = '0s'
      }

        // Removing this to prevent collapsing the sidebar until the new design is deployed
        this.element.classList.remove('min')

      if (this.hasMiddletagTarget)
        this.middletagTarget.style.marginLeft = '240px'
      if (this.hasNavTagTarget)
        this.navtagTarget.style.visibility = 'visible'
      if (this.hasButtonTarget)
        this.buttonTarget.classList.remove('active')

      $(document).ready(function() {
        setTimeout(function() {
          if ($('#aside')[0]) {
            $('#aside')[0].style['transition-duration'] = '0.2s'
            $('#aside')[0].style['-webkit-transition-duration'] = '0.2s'
          }
        }, 500)
      })
    }
  }

  addObserver(){
    // create an observer instance
    let observer = new MutationObserver( (mutations) => {
      mutations.forEach( () => {
        // set session variable (will be deleted on browser close)
        localStorage.setItem('navShouldBeOpen', (this.element.classList.contains('min') ? 0 : 1))
      })
    })

    // configuration of the observer:
    let config = {
      attributes: true,
      childList: false,
      characterData: false
    }

    // pass in the target node, as well as the observer options
    observer.observe(this.element, config)
  }
}
